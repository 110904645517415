/* global rp$, window, document */

import './promotional_banner.scss';

var THE_SCRIPT = function () {
	const $onReady = require('public/js/lib/on-ready');
	require('core-js/fn/array/for-each');

	(function promotionalBannerModule(){
		$onReady({
			rp$, window,
			label: 'promotional_banner',
			fn: function () {
				var $promotional_banners = rp$('.promotional-banner-wrap');

				rp$.each($promotional_banners, function promotionalBanner_Iterator(idx, promo_ban_ele){

					var $banner_wrap = rp$(promo_ban_ele);
					var $banner;
					var $promo_close = rp$('.promo-close');
					var $promo_cookie;
					var banner_max_height = 38;

					// Add ellipsis if text is wrapping
					var checkHeight = function(restrictToMax) {
						var banner_height = 0;
						if(restrictToMax === undefined) {
							restrictToMax = true;
						}

						$banner_wrap.removeClass('text-wrapping');
						$banner_wrap.removeClass('promo-expand');

						if($banner_wrap.is(':visible')) {
							banner_height = $banner_wrap.outerHeight();
						}

						if (restrictToMax && banner_height > banner_max_height) {
							$banner_wrap.addClass('text-wrapping');  // Wrap the text so that it only takes up one line
							banner_height = $banner_wrap.outerHeight();  // Update the banner_height since we just changed the height

							// If we're still too tall, restrict the height
							if (banner_height > banner_max_height) {
								banner_height = banner_max_height;
							}
						}

						var distanceTopCandidates = [
							'.mobile-header-module',
							'#mobile-header-custom',
						];
						distanceTopCandidates.forEach(function (candidateSelector) {
							var el = rp$(candidateSelector);
							// Check if jQuery actually found something
							if (el.length) {
								el.css('top', banner_height+'px');
							}
						});

						// Margin-top: List of things we might need to update, depending on the theme used
						var marginTopCandidates = [
							'.mobile-header-module-spacer',
							'#mobile-menu',
							'#mobile-search',
							'#mobile-drop-downs',
							'#mobile-buffer-row',
						];
						marginTopCandidates.forEach(function (candidateSelector) {
							var el = rp$(candidateSelector);
							// Check if jQuery actually found something
							if (el.length) {
								el.css('margin-top', banner_height+'px');
							}
						});
					};

					//rp$(window).on('resize', debounce(checkHeight, 50));

					// Expand Function
					$banner_wrap.on('click', function promoExpandText() {
						if ($banner_wrap.is('.text-wrapping')) {
							if (!$banner_wrap.is('.promo-expand')) {
								$banner_wrap.addClass('promo-expand');
								checkHeight(false);
							}
							else if ($banner_wrap.is('.promo-expand')) {
								$banner_wrap.removeClass('promo-expand');
								checkHeight();
							}
						}
					});

					// Evaluate for cookie
					$promo_cookie = readCookie("promoBannerClosed");
					if ($promo_cookie == null){
						$banner_wrap.css('display', 'block');
					}

					checkHeight();

					// Close button to hide banner and generate cookie
					$promo_close.on('click', function hidePromoBanner() {
						$banner_wrap.css('display', 'none');
						createCookie("promoBannerClosed", "true", 0);
						checkHeight();
					});

					/* --------------------- Cookie Functions ---------------------- */
					// Create cookie
					function createCookie(name,value,days) {
						if (days) {
							var date = new Date();
							date.setTime(date.getTime()+(days*24*60*60*1000));
							var expires = "; expires="+date.toGMTString();
						}
						else var expires = "";
						document.cookie = name+"="+value+expires+"; path=/";
					}

					// Read cookie
					function readCookie(name) {
						var nameEQ = name + "=";
						var ca = document.cookie.split(';');
						for(var i=0;i < ca.length;i++) {
							var c = ca[i];
							while (c.charAt(0)==' ') c = c.substring(1,c.length);
							if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
						}
						return null;
					}

					// Delete Cookie
					function eraseCookie(name) {
						createCookie(name,"",-1);
					}
				});
			},
		});

	})();
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}
